import "./styles/App.css";
import React, { useState, useEffect } from "react";
import { Route, Routes, Link, useLocation, Navigate } from "react-router-dom";
import Sources from "./components/Sources";
import AppContainer from "./components/AppContainer";
import About from "./components/About";
import NavBar from "./components/NavBar";

const App = () => {
  const [currentApp, setCurrentApp] = useState(
    "https://cif.componc-ucccc.bsd.uchicago.edu/cif-shiny/"
  );
  const [activeTab, setActiveTab] = useState("home");
  let location = useLocation();

  useEffect(() => {
    const pathToTab = {
      "/home": "home",
      "/profiles": "profiles",
      "/bivariate": "bivariate",
      "/data-sources": "data-sources",
      "/about": "about",
    };
    setActiveTab(pathToTab[location.pathname]);
  }, [location.pathname]);

  const changeApp = (url, tab) => {
    if (url !== currentApp) {
      setCurrentApp(url);
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <NavBar changeApp={changeApp} activeTab={activeTab}/>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route
          path="/home"
          element={
            <AppContainer url="https://cif.componc-ucccc.bsd.uchicago.edu/cif-shiny/" />
          }
        />
        <Route
          path="/profiles"
          element={
            <AppContainer
              url="https://cif.componc-ucccc.bsd.uchicago.edu/cif-profiles/"
            />
          }
        />
        <Route
          path="/bivariate"
          element={
            <AppContainer
              url="https://cif.componc-ucccc.bsd.uchicago.edu/cif-bivar/"
            />
          }
        />
        <Route path="/data-sources" element={<Sources />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </div>
  );
};

export default App;
