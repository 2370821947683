// import "../styles/App.css";
import React from "react";

const About = () => {
  return (
    <div id="data-list" 
    className="site"
    >
      <div className="site-title" style={{ textAlign: "center" }}>
        <img
          src="https://live.staticflickr.com/65535/52492234247_c430d09ed5_h.jpg"
          alt="Cancer InFocus"
        //   style="width:50vw; margin-bottom: 52px;"
        />
        <h1 
        // style="margin-bottom: 44px;"
        >ABOUT US</h1>
        <p 
        // style="text-align: justify;"
        >
          Cancer InFocus is a data gathering and visualization platform designed
          to make understanding the cancer burden in a geographic area easier
          through a rapid and repeatable process of tool creation. This platform
          was developed by the Community Impact Office at the University of
          Kentucky Markey Cancer Center, and is made available to others through
          CancerInFocus.org (for data downloads) and a no-cost licensing
          agreement (for access to data gathering and application creation
          code).{" "}
        </p>
        <hr />
      </div>

      <h2>
        <strong>
          <em>What It Does</em>
        </strong>
      </h2>

      <p>
        Cancer InFocus begins with a data gathering program known as CIFTools.
        This program receives a set of US counties as an input and uses that
        input to pull and filter data from numerous publicly available online
        sources, such as the US Census Bureau's American Community Survey, CDC
        Places, the FDA Certified Mammography facility list, and more (see
        available data sources{" "}
        <a
          href="https://cancerinfocus.org/datasources/"
          target="_blank"
          rel="noreferrer noopener"
        >
          here
        </a>
        ). Data is then processed into a standard format, organized by category
        and geographic level, and written to files for use.
      </p>

      <p>
        After the data files are produced, they can be used independently or
        read into the Cancer InFocus Shiny application code (see below) to
        create an interactive mapping application. This application can be
        deployed online for use by anyone interested in better understanding the
        impacts of cancer where they live and work.
      </p>

      <p>
        The generalized nature and rapid processing of these tools allows data
        and applications to be easily updated over time, making the workflow of
        characterizing the cancer burden in an area more efficient. Increased
        efficiency means less time spent gathering data and building tools and
        more time spent on working to improve cancer outcomes.
      </p>

      <hr />

      <h2>
        <strong>
          <em>How You Can Use It</em>
        </strong>
      </h2>

      <p>
        There are two ways in which cancer centers and other interested parties
        can take advantage of the data gathering aspect of Cancer InFocus. Data
        has already been gathered and made available for download for the
        defined catchment areas of all NCI-designated cancer centers{" "}
        <a
          href="https://cancerinfocus.org"
          target="_blank"
          rel="noreferrer noopener"
        >
          here
        </a>{" "}
        . Additional cancer centers that are not NCI-designated can request to
        have their catchment/service areas added to this application by emailing{" "}
        <a href="mailto:ciodata@uky.edu">ciodata@uky.edu</a>.
      </p>

      <p>
        Researchers can also request access to the code behind the data
        gathering process by filling out the following{" "}
        <a
          href="https://redcap.uky.edu/redcap/surveys/?s=FEE7KWDKRYFN88LE"
          target="_blank"
          rel="noreferrer noopener"
        >
          request form{" "}
        </a>
        .
      </p>
      <p>
        If a cancer center is interested in creating their own site-specific
        version of the Cancer InFocus interactive mapping application{" "}
        <a
          href="https://cancerinfocus.uky.edu"
          target="_blank"
          rel="noreferrer noopener"
        >
          (example)
        </a>
        , they may do so through the completion of a no-cost licensing agreement
        with the University of Kentucky Markey Cancer Center. The licensing form
        may be obtained by emailing{" "}
        <a href="mailto:ciodata@uky.edu">ciodata@uky.edu</a>. Centers who
        license access to the Cancer InFocus Shiny application code will be
        responsible for deploying and maintaining their own versions of Cancer
        InFocus.
      </p>

      <hr />

      <h2>
        <strong>
          <em>Acknowledgments</em>
        </strong>
      </h2>

      <p>
        This work was aided and inspired by the work of others seeking to
        emphasize the importance of cancer center catchment areas and assess the
        holes left to be filled. Two articles dealing with the geographic scope
        of US cancer centers were of particular importance to us:{" "}
        <a
          href="https://aacrjournals.org/cebp/article/31/5/955/694731/Assessing-the-Coverage-of-US-Cancer-Center-Primary"
          target="_blank"
          rel="noreferrer noopener"
        >
          <em>
            Assessing the Coverage of US Cancer Center Primary Catchment Areas
          </em>{" "}
          (2022)
        </a>
        , by Leader, McNair et al. and{" "}
        <a
          href="https://aacrjournals.org/cebp/article-abstract/31/5/965/694730/A-National-Map-of-NCI-Designated-Cancer-Center?redirectedFrom=fulltext"
          target="_blank"
          rel="noreferrer noopener"
        >
          <em>
            A National Map of NCI-Designated Cancer Center Catchment Areas on
            the 50th Anniversary of the Cancer Centers Program
          </em>{" "}
          (2022)
        </a>{" "}
        by DelNero, Buller et al.
      </p>

      <h2>
        <strong>
          <em>Citation</em>
        </strong>
      </h2>

      <p>
        Justin Todd Burus, Lee Park, Caree R. McAfee, Natalie P. Wilhite, Pamela
        C. Hull; Cancer InFocus: Tools for Cancer Center Catchment Area
        Geographic Data Collection and Visualization.{" "}
        <em>Cancer Epidemiol Biomarkers Prev</em> 2023;{" "}
        <a
          href="https://doi.org/10.1158/1055-9965.EPI-22-1319"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://doi.org/10.1158/1055-9965.EPI-22-1319
        </a>
        {/* <!-- partial -->
  <script src="./script.js"></script> */}
      </p>
    </div>
  );
};

export default About;
