import React from "react";

const AppFrame = ({ url, hideLoader, isLoading}) => {
  return (
    <div>
      <iframe
        id="appIframe"
        title="shiny"
        className="container"
        onLoad={hideLoader}
        src={url}
        style={{
            opacity: isLoading ? 0 : 1 // Hide iframe when loading
        }}
      ></iframe>
    </div>
  );
};

export default AppFrame;
