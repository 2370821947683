import React from "react";
import { Link } from "react-router-dom";

const NavBar =({changeApp,activeTab})=>{

    const myFunction = (event) => {
        event.preventDefault();
        var x = document.getElementById("myTopnav");
        if (x.className === "topnav") {
            x.className += " responsive";
        } else {
            x.className = "topnav";
        }
        };    
    return (
        <div id="myTopnav" className="topnav">
        <Link
        to="/home"
        onClick={() =>
            changeApp(
            "https://cif.componc-ucccc.bsd.uchicago.edu/cif-shiny/",
            "home"
            )
        }
        className={activeTab === "home" ? "active" : ""}
        >
        Home
        </Link>
        <div
        className={`dropdown ${
            activeTab === "bivariate" || activeTab === "profiles"
            ? "active"
            : ""
        }`}
        >
        <button className="dropbtn">
            {" "}
            Other Apps
            <i className="fa fa-caret-down carrot-icon"></i>
        </button>
        <div className="dropdown-content">
            <Link
            to="/profiles"
            onClick={() =>
                changeApp(
                "https://cif.componc-ucccc.bsd.uchicago.edu/cif-profiles/",
                "profiles"
                )
            }
            className={activeTab === "profiles" ? "active" : ""}
            >
            CIF Profiles
            </Link>
            <Link
            to="/bivariate"
            onClick={() =>
                changeApp(
                "https://cif.componc-ucccc.bsd.uchicago.edu/cif-bivar/",
                "bivariate"
                )
            }
            className={activeTab === "bivariate" ? "active" : ""}
            >
            CIF<sup>2</sup> (bivariate)
            </Link>
        </div>
        </div>
        <Link
        to="/data-sources"
        onClick={() => changeApp("/data-sources", "data-sources")}
        className={activeTab === "data-sources" ? "active" : ""}
        >
        Data Sources
        </Link>
        <Link
        to="/about"
        onClick={() => changeApp("/about", "about")}
        className={activeTab === "about" ? "active" : ""}
        >
        About
        </Link>
        <a
        href="javascript:void(0);"
        style={{ fontSize: "1.7vh" }}
        className="icon"
        onClick={(event) => myFunction(event)}
        >
        &#9776;
        </a>
    </div>
    );
};

export default NavBar;