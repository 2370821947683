import React from 'react';
import '../styles/Loading.css';

const Loading = () => (
  <div className="loader-container">
    <div className="loader"></div>
    <p>Loading, please wait...</p>
  </div>
);

export default Loading;
