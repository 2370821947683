import React, {useState, useEffect} from "react";
import Loading from "./Loading";
import AppFrame from "./AppFrame";

const AppContainer = ({url})=>{

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
      }, [url]);

    const hideLoader = () => {
        setIsLoading(false);
      };

    return(
        <div>
        {isLoading && <Loading />};
        {
          <AppFrame url={url}
          hideLoader={hideLoader}
          isLoading={isLoading}
          />
        };
        </div>
    );

};

export default AppContainer;
